<template>
  <div id="#draggable-container" class="absolute z-30 bg-white shadow px-6 flex rounded-xl ml-14" ref="draggableContainer" style="width: 90vw; left: 1vw; grid-template-rows: auto 50px; flex-direction: column; bottom: 0px">
    <div id="draggable-header" class="border-b border-divider flex justify-between mt-2" style="height: 50px" v-dragged="onDragged">
      <div class="flex text-darkblue font-SourceSansPro" style="align-self: center">
        <div class="div">
          <p class="truncate text-darkblue text-base font-bold">
            {{ tableData && tableData.report ? `${tableData.report.name}, ${paginatedReport.groups ? tableData.group_name : tableData.unit_name}` : '' }}
          </p>
          <div ref="infoDateElement" class="flex cursor-pointer items-center">
            <p class="text-sm font-semibold truncate">
              {{ tableData && tableData.report ? `${dateTitleFrom} - ${dateTitleTo}` : '' }}
            </p>
          </div>
        </div>

        <i class="el-icon-refresh-right flex items-center ml-3 cursor-pointer" @click="refreshReport"></i>
      </div>
      <div class="flex justify-between header-cont items-center">
        <div class="mt-1">
          <el-popover v-if="newChartDates.length" placement="bottom" width="160" trigger="hover" class=" " popper-class="info-popoverlay">
            <div class="info-container">
              <p>Применить временной интервал из данного отчета и запросить новый.</p>
              <p>Интервал: {{ newChartDates[0] }} — {{ newChartDates[1] }}</p>
            </div>

            <div @click="handleNewChartDates" slot="reference">
              <InfoDate class="mb-1 mr-2" />
            </div>
          </el-popover>
        </div>
        <div class="flex items-center">
          <!-- <el-popover
            v-model="isPopoverIntervalsVisible"
            placement="bottom"
            trigger="click"
            popper-class="chartIntervals "
          >
            <h3 class="text-gray-600 font-bold mb-2">
              {{ $t('reports.chart.intervals') }}:
            </h3>
            <div class="intervals_block">
              <skif-checkbox
                class="m-0"
                v-model="isTripsIntervals"
                @change="showTripsIntervals(isTripsIntervals)"
              >
              </skif-checkbox>
              <div class="color grey"></div>
              <p>{{ $t('monitoring.trips') }}</p>
            </div>
            <div class="intervals_block">
              <skif-checkbox
                v-model="isDrainsIntervals"
                @change="showDrainsIntervals(isDrainsIntervals)"
                class="m-0"
              >
              </skif-checkbox>
              <div class="color orange"></div>
              <p>{{ $t('reports.chart.thefts') }}</p>
            </div>
            <div class="intervals_block">
              <skif-checkbox
                v-model="isFillingsIntervals"
                @change="showFillingsIntervals(isFillingsIntervals)"
                class="m-0"
              >
              </skif-checkbox>
              <div class="color blue"></div>
              <p>{{ $t('reports.chart.fillings') }}</p>
            </div>

            <DotsIcon class="cursor-pointer" slot="reference" />
          </el-popover> -->
          <el-popover v-model="isPopoverVisible" placement="bottom" trigger="click" popper-class="sizeScreenReport">
            <el-radio-group v-model="sizeScreen" @change="getHeight">
              <el-radio label="miniScreen">
                {{ $t('reports.reports-size-screen.minimum') }}
              </el-radio>
              <el-radio label="thirdScreen">
                {{ $t('reports.reports-size-screen.thirdScreen') }}
              </el-radio>
              <el-radio label="medScreen">
                {{ $t('reports.reports-size-screen.medium') }}
              </el-radio>
              <el-radio label="maxScreen">
                {{ $t('reports.reports-size-screen.maximum') }}
              </el-radio>
            </el-radio-group>
            <WindowIcon class="cursor-pointer" slot="reference" />
          </el-popover>
          <i class="el-icon-close text-2xl cursor-pointer text-darkblue font-semibold ml-3" @click="handlClose"></i>
        </div>
      </div>
    </div>
    <div class="flex-flex-col relative col-start-1" v-loading="ischartloading" :style="`height:${height + 200}; flex-grow: 1; z-index: 10;`">
      <div v-if="!loading && !newMarkers.length && (!newMarkers.data || !newMarkers.data.length)" class="not-data">
        <div class="reloadReport">
          <InfoIcon :color="'#20579A'" />
          <div class="text">
            <p class="no-data">{{ $t('reportsTranslater.noData') }}</p>
            <p class="reload" @click="reloadReport">
              {{ $t('reportsTranslater.rewrite') }}
            </p>
          </div>
        </div>
      </div>
      <apexchart :ref="chartIndexes" :options="chartOptions" :series="seriesChart" height="100%" type="line" :key="reRenderApexChart" />
    </div>
    <div class="flex items-center justify-center m-0 p-0" style="height: 40px" v-dragged="onDragged" v-if="!closeToChange">
      <el-select
        v-if="!loading"
        v-model="theRawParam"
        :class="[
          'w-48',
          {
            '--one-value': theRawParam.length < 2,
            '--disabled': selectDisabled
          }
        ]"
        style="min-width: 48rem; max-width: 14rem"
        filterable
        multiple
        :multiple-limit="5"
        :disabled="selectDisabled"
        @visible-change="addRawParam"
        @change="onSelectChange"
      >
        <el-option v-if="item.key !== 'is_trip'" v-for="(item, index) in possibleColumns" :key="index" :label="item.name" :value="item.key" />
      </el-select>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
// import { reportsApi } from '@/api'
import reportTab from '@/mixins/reportTab.js'

import draggable from 'vuedraggable'
import moment from 'moment'
import WindowIcon from '@/components/reports/icons/WindowIcon.vue'
import DotsIcon from '@/components/reports/icons/DotsIcon.vue'
import InfoIcon from '@/components/reports/icons/InfoIcon.vue'
import InfoDate from '@/components/reports/icons/InfoDate.vue'

// import newReports from '@/mixins/newReports.js'
export default {
  name: 'ReportsChart',
  components: {
    draggable,
    WindowIcon,
    InfoIcon,
    InfoDate,
    DotsIcon
  },
  mixins: [reportTab],
  data() {
    return {
      currentAnnotations: [],
      currentConfig: {},
      isPopoverIntervalsVisible: false,
      isTripsIntervals: true,
      isFillingsIntervals: false,
      isDrainsIntervals: false,
      chartKey: 1,
      newMarkers: [],
      zoomMarkers: null,
      isExtraChart: false,
      currentTimeSeries: null,
      ischartloading: false,
      oldFullArr: [],
      seriesBase: [],
      min: '',
      max: '',
      processing: false,
      beforeZoomX: '',
      updateChartAfterChangeHight: false,
      firstTimeFirst: '',
      firstTimeLast: '',
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      },
      isRendered: false,
      series: [],
      seriesChart: [],
      availableSeries: [],
      theRawParam: [],
      lastRawParam: [],
      rawParamsToSend: [],
      isChartMounted: false,
      activeCharts: [],
      newPaginatedReport: {},
      selectDisabled: false,
      isSpecificChart: false,
      fromForSpecChart: '',
      toForSpecChart: '',
      currentZoom: '',
      oldZoom: 'in',
      oldSeries: [],
      isChartUpdated: false,
      maxRows: '',
      oldMaxRows: '',
      dateTitleFrom: '',
      dateTitleTo: '',
      oldMarkers: [],
      isNewData: false,
      newChartDates: [],
      closeToChange: false,
      chartIndexes: Math.floor(Math.random() * 1000),
      fuelAnnotations: [],
      reRenderApexChart: 0
    }
  },
  computed: {
    ...mapState('reports', {
      chartZoom: state => state.chartZoom,
      moreReportData: state => state.moreReportData.columns,
      newMaxRows: state => state.moreReportData.max_rows,
      reportTemplates: state => state.templates,
      showReport: state => state.showReport
    }),
    ...mapState('login', {
      me: state => state.me
    }),
    ...mapGetters('reports', ['tabledata', 'getMagazineDepend', 'getTableTypes']),
    ...mapGetters('units', ['getTerminalType']),
    ...mapGetters('dictionary', ['getParamsByKey']),
    zoomState() {
      return this.currentZoom
    },

    params() {
      if (this.isNotEmpty(this.newPaginatedReport)) {
        return this.newPaginatedReport
      }
      return this.paginatedReport || {}
    },
    chartOptions: {
      get() {
        return {
          title: {
            text: this.tableData && this.tableData.report ? `${this.tableData.report.name}, ${this.paginatedReport.groups ? this.tableData.group_name : this.tableData.unit_name}` : '',
            offsetY: 0,
            margin: -2,
            align: 'center',
            floating: true,
            style: {
              fontSize: '11px',
              color: '#5477A9',
              fontWeight: 600
            }
          },
          subtitle: {
            align: 'center',
            floating: false,
            margin: -2,
            offsetY: 14,
            text: this.tableData && this.tableData.report ? `${this.paginatedReport.from} - ${this.paginatedReport.to}` : '',
            style: {
              fontSize: '10px',
              color: '#5477A9'
            }
          },
          noData: {
            text: this.$t('loading'),
            offsetY: -22,
            style: {
              align: 'center',
              verticalAlign: 'middle',

              color: '#608EB7',
              fontSize: '24px',
              fontFamily: 'Source Sans Pro'
            }
          },
          stroke: {
            width: [1, 1, 1, 1, 1, 1],
            curve: 'stepline'
          },
          sparkline: {
            enabled: true
          },
          chart: {
            defaultLocale: 'ru',
            locales: [
              {
                name: 'ru',
                options: {
                  months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                  shortMonths: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
                  days: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
                  shortDays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
                  toolbar: {
                    exportToSVG: `${this.$t('save')} SVG`,
                    exportToPNG: `${this.$t('save')} PNG`,
                    exportToCSV: `${this.$t('save')} CSV`,
                    menu: this.$t('reports.menu'),
                    selection: this.$t('reports.select'),
                    selectionZoom: this.$t('reports.select_with_magnification'),
                    zoomIn: this.$t('reports.increase'),
                    zoomOut: this.$t('reports.reduce'),
                    pan: this.$t('reports.moving'),
                    reset: this.$t('reports.reset_magnification')
                  }
                }
              }
            ],
            events: {
              beforeMount(chartContext, config) {
                const comp = this
                const openedReports = JSON.parse(localStorage.getItem('openedReports'))
                if (openedReports.length > 1) {
                  console.log('before update , second table!')
                }
              },
              dataPointMouseEnter(event) {
                event.path[0].style.cursor = 'pointer'
              },
              beforeZoom: (chartContext, { xaxis, yaxis }) => {
                const comp = this

                comp.currentZoom = xaxis.max - xaxis.min

                const maindifference = new Date(this.firstTimeLast).valueOf() - new Date(this.firstTimeFirst).valueOf()

                const currentScreen = window.screen.width
                const pointsToRequest = currentScreen >= 1920 ? 5000 : currentScreen >= 1280 && currentScreen < 1920 ? 3000 : currentScreen >= 800 && currentScreen < 1280 ? 1500 : 500
                comp.beforeZoomX = { xaxis }
                const zoomdifference = xaxis.max - xaxis.min
                comp.min = xaxis.min
                comp.max = xaxis.max
                if (zoomdifference > maindifference) {
                  comp.processing = false
                  comp.maxRows = comp.oldMaxRows
                  return {
                    xaxis: {
                      min: this.firstTimeFirst,
                      max: this.firstTimeLast
                    }
                  }
                }
                if (this.processing === true) {
                  return
                }

                const formData = {
                  ...this.params,
                  from: moment(xaxis.min).format('YYYY-MM-DD HH:mm:ss'),
                  to: moment(xaxis.max).format('YYYY-MM-DD HH:mm:ss'),
                  max: pointsToRequest
                }
                this.newChartDates = [formData.from, formData.to]

                if (comp.maxRows >= 5000) {
                  comp.processing = true

                  setTimeout(() => {
                    // когда выделили отрезок графика запрашиваем новый отчет с новыми данными
                    console.log('! когда выделили отрезок графика запрашиваем новый отчет с новыми данными')
                    this.$store.dispatch('reports/GET_MOREREPORTS', formData)
                    comp.isChartUpdated = !comp.false
                  }, 0)
                }

                comp.min = xaxis.min
                comp.max = xaxis.max

                return {
                  xaxis: {
                    min: xaxis.min,
                    max: xaxis.max
                  }
                }
              },
              beforeResetZoom: (chartContext, opts) => {
                const comp = this

                comp.maxRows = comp.oldMaxRows
                comp.processing = false
                this.newChartDates = []
                /*
                 *- reset the trips intervals on the chart after reseting zoom ( click home button )
                 */
                this.$nextTick(() => {
                  const theftsIntervals = this.tableData.report.columns.filter(el => el.key === 'trips_intervals')
                  if (theftsIntervals.length > 0) {
                    this.handleAnnotationChart(theftsIntervals[0].data, 'trips_intervals')
                  }
                  try {
                    this.$refs[this.chartIndexes].updateOptions(this.chartOptions)
                  } catch (e) {
                    console.error('Catch updateOptions after reset zoom', e)
                  }
                })
              },
              zoomed: (chartContext, { xaxis }) => {
                const minX = xaxis.min
                const maxX = xaxis.max
                const seriesData = chartContext.w.globals.series
                const seriesXData = chartContext.w.globals.seriesX
                const seriesNames = chartContext.w.globals.seriesNames

                const yaxis = this.seriesChart.map((serie, index) => {
                  const visibleYValues = []
                  const isVisible = chartContext.w.globals.collapsedSeries.indexOf(index) === -1

                  // console.log(`Series ${seriesNames[index]} - isVisible: ${isVisible}`)

                  if (isVisible) {
                    seriesXData[index].forEach((x, pointIndex) => {
                      if (x >= minX && x <= maxX) {
                        visibleYValues.push(seriesData[index][pointIndex])
                      }
                    })

                    let minY = visibleYValues.length > 0 ? Math.min(...visibleYValues) : chartContext.w.globals.yAxisScale[index].min
                    let maxY = visibleYValues.length > 0 ? Math.max(...visibleYValues) : chartContext.w.globals.yAxisScale[index].max

                    return {
                      ...chartContext.w.config.yaxis[index],
                      min: minY,
                      max: maxY
                    }
                  } else {
                    // console.log(`Series ${seriesNames[index]} is not visible`)
                    return chartContext.w.config.yaxis[index]
                  }
                })

                if (Array.isArray(yaxis) && yaxis[0].min !== undefined) {
                  chartContext.updateOptions(
                    {
                      yaxis: yaxis
                    },
                    false,
                    false
                  )
                  this.updateYAxisRange(chartContext, { xaxis, yaxis })
                  this.isSpecificChart = false
                  this.saveChartImg('apexchart').then(resp => {
                    this.$store.commit('reports/CHART_BASE46', resp)
                  })
                } else {
                  // console.error('Произошло исключение!')
                }
              },

              scrolled: (chartContext, { xaxis }) => {
                if (this.getMagazineDepend) {
                  this.$store.commit('reports/CHANGE_MAGAZINE_DEPEND', false)
                }
              },
              markerClick: (event, chartContext, { seriesIndex, dataPointIndex, config }) => {
                let currentData
                if (this.isNewData) {
                  currentData = this.newMarkers.data[dataPointIndex][1]
                } else {
                  const markers = this.tableData.report.columns.filter(el => el.key === '_marker_latlon_chart')
                  currentData = markers[0].data[dataPointIndex]
                }

                let currentDatePoint
                let sec

                if (this.isNewData) {
                  this.zoomMarkers = this.newMarkers
                  sec = this.newMarkers.data[dataPointIndex][0]
                } else {
                  sec = this.zoomMarkers ? this.zoomMarkers.data[dataPointIndex][0] : this.newMarkers[dataPointIndex][0]
                }

                const time = new Date(sec)
                currentDatePoint = moment(time).format('YYYY-MM-DD HH:mm:ss')

                console.log('this.dateTitleFrom', this.dateTitleFrom)
                console.log("moment(this.dateTitleFrom, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss')", moment(this.dateTitleFrom, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss'))
                console.log("moment(this.dateTitleFrom, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')", moment(this.dateTitleFrom, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'))
                console.log("moment(this.dateTitleFrom).format('YYYY-MM-DD HH:mm:ss')", moment(this.dateTitleFrom).format('YYYY-MM-DD HH:mm:ss'))

                if (this.getMagazineDepend) {
                  const data = {
                    units: [
                      {
                        id: this.paginatedReport.units[0].id
                      }
                    ],
                    from: this.paginatedReport.from,
                    datepoint: currentDatePoint
                  }

                  console.log('data!?', data)

                  this.$store.dispatch('reports/GET_CHART_INDEX', data)
                } else {
                  this.$store.dispatch('reports/chartLocations', currentData)
                }
              },

              /**
               * - when we click on the legend item . we verify if its поездки. then we should show/hide the intervals in the chart
               *  @param {Number} seriesIndex - index of the series (поездки всегда 0)
               * -in each trips_intervals we add isHidding - check handleAnnotationChart()
               **/
              legendClick: (chartContext, seriesIndex, config) => {
                let shouldUpdateChart = false
                this.chartOptions.annotations.xaxis = this.chartOptions.annotations.xaxis.map(item => {
                  if (item.id === 'trips_intervals' && seriesIndex === 0) {
                    item.isHidding = !item.isHidding
                    shouldUpdateChart = true
                    return item.isHidding ? { ...item, opacity: 0 } : { ...item, opacity: 0.4 }
                  }
                  return item
                })
                if (shouldUpdateChart) {
                  this.$refs[this.chartIndexes].updateOptions(this.chartOptions)
                }
              }
            },
            width: '100%',
            animations: {
              enabled: false,
              initialAnimation: {
                enabled: false
              }
            },

            annotations: [],
            stacked: false,

            toolbar: {
              show: true,

              offsetY: -10,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                reset: true | '<img class="downloadexcel" src="/static/icons/reset.png" width="10">',
                customIcons: [
                  {
                    icon: `<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.21017 16.1695C2.65794 16.1767 2.21614 16.6303 2.2234 17.1825C2.23066 17.7347 2.68422 18.1765 3.23646 18.1693L15.2354 18.0116C15.7877 18.0043 16.2294 17.5508 16.2222 16.9985C16.2149 16.4463 15.7614 16.0045 15.2091 16.0118L3.21017 16.1695ZM5.81577 8.42743C5.42014 8.04211 4.78704 8.05043 4.40167 8.44602C4.01632 8.84164 4.02464 9.47475 4.42026 9.86011L8.47248 13.8072C8.8681 14.1926 9.50121 14.1842 9.88657 13.7886L13.8337 9.7364C14.219 9.34077 14.2107 8.70767 13.8151 8.3223C13.4195 7.93698 12.7863 7.9453 12.401 8.34089L10.1384 10.6637L10.0125 1.07876C10.0052 0.526506 9.55163 0.0847286 8.9994 0.0919862C8.44717 0.0992437 8.00537 0.552789 8.01263 1.10504L8.13859 10.69L5.81577 8.42743Z" fill="currentColor"/>
                      </svg>
                      `,
                    index: 4,
                    title: `${this.$t('save')} .xlsx`,
                    class: 'exceldownload',
                    click: () => {
                      const self = this
                      const from = moment(self.paginatedReport.from)
                      const to = moment(self.paginatedReport.to)
                      const days = to.diff(from, 'days')

                      // if (days < 14) {
                      const formData = {
                        units: self.paginatedReport.units,
                        template_tables: [
                          {
                            id: 'c9e29985-ed8e-4b5a-9356-c1d393207eaa'
                          }
                        ],
                        from: self.paginatedReport.from,
                        to: self.paginatedReport.to,
                        selected_columns: self.theRawParam.map(param => {
                          return {
                            name: param,
                            key: param
                          }
                        }),
                        max: 1000,
                        offset: 0,
                        sortField: null,
                        sortDesc: false,
                        conditions: []
                      }
                      window.location.href = `${window.location.protocol}//${location.host}/api_v1/report_excel?body=${JSON.stringify(formData)}`
                      // } else {
                      //   self.$showError(
                      //     'Невозможно загрузить график за более 2 недели'
                      //   )
                      // }
                    }
                  },
                  {
                    icon: `<svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.22502 5.99999C2.22502 4.14749 3.73085 2.64166 5.58335 2.64166H9.91669V0.583328H5.58335C2.59335 0.583328 0.166687 3.00999 0.166687 5.99999C0.166687 8.98999 2.59335 11.4167 5.58335 11.4167H9.91669V9.35833H5.58335C3.73085 9.35833 2.22502 7.85249 2.22502 5.99999ZM6.66669 7.08333H15.3334V4.91666H6.66669V7.08333ZM16.4167 0.583328H12.0834V2.64166H16.4167C18.2692 2.64166 19.775 4.14749 19.775 5.99999C19.775 7.85249 18.2692 9.35833 16.4167 9.35833H12.0834V11.4167H16.4167C19.4067 11.4167 21.8334 8.98999 21.8334 5.99999C21.8334 3.00999 19.4067 0.583328 16.4167 0.583328Z" fill="currentColor"/>
                            </svg>`,
                    index: 4,
                    title: this.$t('reports.link_to_magazine'),
                    class: `depend ${this.getMagazineDepend === null ? 'disabled' : this.getMagazineDepend ? 'active' : 'nonactive'}`,
                    click: () => {
                      if (this.getMagazineDepend !== null) {
                        console.log(1, this.chartOptions)
                        this.$store.commit('reports/CHANGE_MAGAZINE_DEPEND', !this.getMagazineDepend)
                        this.refreshReport()
                      }
                    }
                  }
                ]
              },
              export: {
                sss: {
                  filename: undefined,
                  columnDelimiter: ',',
                  headerCategory: 'category',
                  headerValue: 'value',
                  dateFormatter(timestamp) {
                    return new Date(timestamp).toDateString()
                  }
                },
                csv: {
                  filename: undefined,
                  columnDelimiter: ',',
                  headerCategory: 'category',
                  headerValue: 'value',
                  dateFormatter(timestamp) {
                    return new Date(timestamp).toDateString()
                  }
                },
                svg: {
                  filename: undefined
                },
                png: {
                  filename: undefined
                }
              }
            },
            id: 'apexchart'
          },
          colors: ['#77B6EA', '#545454'],
          dataLabels: {
            enabled: false
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.1
            }
          },
          markers: {
            size: 0
          },
          xaxis: {
            type: 'datetime',
            labels: {
              datetimeUTC: false,
              format: 'dd HH:mm:ss'
            },
            tooltip: {
              enabled: false
            }
          },
          yaxis: {
            crosshairs: {
              show: false
            }
          },

          legend: {
            show: true,
            markers: {}
          },
          tooltip: {
            enabled: true,
            onDatasetHover: {
              highlightDataSeries: false
            },
            x: {
              show: true,
              format: 'dd-MM HH:mm:ss',
              formatter: undefined
            },
            marker: {
              show: true
            }
          },
          annotations: {
            xaxis: []
          }
        }
      },
      set(value) {
        return value
      }
    },

    rowTableDataKeys() {
      console.log('zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz')
      return this.rowTableData.map(rtd => rtd.key)
    },
    rawParams() {
      return this.paginatedReport.units ? this.getParamsByKey(this.getTerminalType(this.paginatedReport.units[0].id)) : []
    },
    chartNumbers() {
      return this.activeCharts.length !== 0 ? this.activeCharts.map(arr => arr['#']) : []
    },
    activeChartsIds() {
      return this.activeCharts.length !== 0 ? this.activeCharts.map(i => i.key) : []
    },

    loading() {
      return this.$store.getters['reports/loading'](this.reportId)
    },
    reportTables() {
      return this.$parent.reportTables
    }
  },
  watch: {
    newChartDates(val) {
      val.length === 0 ? this.resetSeries() : null
    },
    showReport() {
      this.$nextTick(() => {
        const theftsIntervals = this.tableData.report.columns.filter(el => el.key === 'thefts_intervals')

        if (theftsIntervals.length > 0) {
          this.handleAnnotationChart(theftsIntervals[0].data, 'thefts_intervals')
        }
        const fillingsIntervals = this.tableData.report.columns.filter(el => el.key === 'fillings_intervals')
        if (fillingsIntervals.length > 0) {
          this.handleAnnotationChart(fillingsIntervals[0].data, 'fillings_intervals')
        }
        try {
          this.$refs[this.chartIndexes].updateOptions(this.chartOptions)
        } catch (e) {
          console.error('Catch updateOptions', e)
        }
      })
    },
    // isPopoverIntervalsVisible(val) {
    //   if (!val) {
    //     const selectedColumns = this.theRawParam.map((param) => {
    //       return {
    //         name: param,
    //         key: param
    //       }
    //     })
    //     const selected_intervals = []
    //     if (this.isFillingsIntervals) {
    //       selected_intervals.push({
    //         key: 'fillings_intervals'
    //       })
    //     }
    //     if (this.isDrainsIntervals) {
    //       selected_intervals.push({
    //         key: 'thefts_intervals'
    //       })
    //     }
    //     if (this.isDrainsIntervals || this.isFillingsIntervals) {
    //       const formData = {
    //         template_tables: this.params.template_tables,
    //         units: this.params.units ? this.params.units : this.params.groups,
    //         from: this.params.from,
    //         to: this.params.to,
    //         selected_columns: selectedColumns,
    //         selected_intervals
    //       }
    //       this.currentConfig = formData
    //       this.$store.dispatch('reports/GET_REPORT', formData)
    //     }
    //     this.$refs[this.chartIndexes].updateOptions(this.chartOptions)
    //   }
    // },
    theRawParam(val, oldval) {
      if (val.length === 0 && oldval.length > 0) this.theRawParam = [oldval[0]]
    },
    tabledata(val) {
      console.log('tabledata...')
    },
    newMaxRows(val) {
      this.maxRows = val
    },
    isChartUpdated(val) {
      this.ischartloading = true
    },
    moreReportData(val) {
      console.log('moreReportData', val)
      const self = this
      const data = []
      const pro = new Promise((resolve, reject) => {
        this.series.forEach(el => {
          val.forEach(item => {
            if (el.key === item.key) {
              // let fullArr
              // fullArr = [...el.data, ...item.data]
              // fullArr = [...new Set(fullArr)]
              // // data.push(item.data)

              // fullArr = { ...el, data: fullArr }
              data.push({ ...el, data: item.data })
            }
          })
        })
        resolve()
      })

      pro.then(() => {
        val.forEach(item => (item.key === 'datepoint' ? (this.currentTimeSeries = item) : ''))
        const markers = val.filter(el => el.key === '_marker_latlon_chart')
        // this.newMarkers = markers[0].data
        data.push(markers[0].data)
        console.log('first convertData')
        self.convertData(data, true)

        // let self = this
        // self.$refs.chartApex.updateSeries(data)
        // self.$refs.chartApex.zoomX(self.min, self.max)
        // self.processing = false
        // this.maxRows = this.newMaxRows
      })

      // let colors = ['#77B6EA', '#545454']
      // await self.$refs.chartApex.updateOptions({
      //   colors: ['rgba(0,0,0,0)'],
      // })
      // await self.$refs.chartApex.updateOptions({ colors: colors })

      // console.log('first serie', this.series[0].data.length)
      // let data = []
      // this.series.forEach((el) => {
      //   val.forEach((item) => {
      //     if (el.key === item.key) {
      //       // let fullArr = [...el.data, ...item.data]
      //       // el.data = [...new Set(fullArr)]
      //       // el.data.push(item.data)
      //       let newdata = { name: el.name, data: item.data }
      //       data.push(newdata)
      //     }
      //   })
      // })

      // let self = this
      // this.$refs[this.chartIndexes].appendData(data).then((res) => {
      //   this.$refs[this.chartIndexes].updateSeries(self.series)
      //   console.log('res', res)
      // })
      // console.log('UPDATED THE SERIES', this.series[0].data.length)
    },
    zoomState(oldVal, newVal) {
      this.$store.commit('reports/REMOVE_CHART_LOCATIONS')
      if (newVal && oldVal >= newVal) {
        this.oldZoom = 'out'
      } else {
        this.oldZoom = 'in'
      }
    },
    async chartZoom(val) {
      this.fromForSpecChart = moment(val[0]).format('YYYY-MM-DD HH:mm:ss')
      this.toForSpecChart = moment(val[1]).format('YYYY-MM-DD HH:mm:ss')

      this.isSpecificChart = true

      this.$refs[this.chartIndexes].zoomX(new Date(this.fromForSpecChart).getTime(), new Date(this.toForSpecChart).getTime())
    },
    updateChartAfterChangeHight() {
      if (this.$refs[this.chartIndexes]) {
        try {
          this.$refs[this.chartIndexes].updateOptions(this.chartOptions)
        } catch (e) {
          console.error('Catch updateOptions', e)
        }
      }
    },

    rowTableData(val) {
      this.convertData(val)
    },

    async sizeScreen(val) {
      await this.$nextTick()
      this.ischartloading = true
      this.getHeightScreen(val)
      this.clearAnnotations()
      await this.$nextTick()
      this.ischartloading = false
      this.makeAnnotations()
    }
  },
  methods: {
    clearAnnotations() {
      this.fuelAnnotations.forEach(id => {
        this.$refs[this.chartIndexes]?.removeAnnotation(id)
      })
      this.fuelAnnotations = []
    },

    makeAnnotations() {
      let newOffsetY = this.getNewOffsetY()
      this.handleIntervals('fillings_intervals', newOffsetY)
      this.handleIntervals('thefts_intervals', newOffsetY)
      this.handleIntervals('trips_intervals')
    },

    getHeightScreen(sizeScreen = 'thirdScreen', isBarShown = false) {
      this.isPopoverVisible = false
      if (sizeScreen === 'thirdScreen') {
        if (isBarShown) {
          this.height = window.innerHeight / 3 - 7.5 + 120
        } else {
          this.height = window.innerHeight / 3 - 7.5
        }
      } else if (sizeScreen === 'medScreen') {
        if (isBarShown) {
          this.height = window.innerHeight / 2 - 5 + 120
        } else {
          this.height = window.innerHeight / 2 - 5
        }
      } else if (sizeScreen === 'maxScreen') {
        this.height = window.innerHeight - 10
      } else if (sizeScreen === 'barChart') {
        this.height += 120
      } else if (isBarShown) {
        this.height = window.innerHeight / 4 + 120
      } else {
        this.height = window.innerHeight / 4
      }
      this.updateChartAfterChangeHight = !this.updateChartAfterChangeHight
      if (this.tableType === 'finance') {
        this.height = window.innerHeight - 10
      }
    },

    getNewOffsetY() {
      return 161 - this.height
    },

    resetSeries() {
      this.$refs[this.chartIndexes]?.resetSeries()
      this.reRenderApexChart++

      // TMP fix
      this.$nextTick(() => {
        const theftsIntervals = this.tableData.report.columns.filter(el => el.key === 'thefts_intervals')
        if (theftsIntervals.length > 0) {
          this.handleAnnotationChart(theftsIntervals[0].data, 'thefts_intervals')
        }
        const fillingsIntervals = this.tableData.report.columns.filter(el => el.key === 'fillings_intervals')
        if (fillingsIntervals.length > 0) {
          this.handleAnnotationChart(fillingsIntervals[0].data, 'fillings_intervals')
        }

        try {
          this.$refs[this.chartIndexes].updateOptions(this.chartOptions)
        } catch (e) {
          console.error('Catch updateOptions', e)
        }
      })
    },

    /**
     * Updates the y-axis range based on the visible x-axis values.
     * Preserves existing annotations and y-axis styles.
     *
     * @param {Object} chart - The ApexCharts instance.
     * @param {Object} axes - Contains the min and max values of the x-axis.
     */
    updateYAxisRange(chart, { xaxis }) {
      // Get the minimum and maximum visible x-axis values (timestamps)
      let minX = xaxis.min
      let maxX = xaxis.max

      // Access visible data using series data
      let visibleYValues = []
      let seriesData = chart.w.globals.series
      let seriesXData = chart.w.globals.seriesX

      seriesXData.forEach((xData, seriesIndex) => {
        let yValuesForSeries = []
        xData.forEach((x, pointIndex) => {
          if (x >= minX && x <= maxX) {
            yValuesForSeries.push(seriesData[seriesIndex][pointIndex])
          }
        })
        visibleYValues.push({
          seriesIndex: seriesIndex,
          yValues: yValuesForSeries
        })
      })

      // Determine the new min and max y-axis values for each series
      let newYAxisOptions = visibleYValues.map(visibleSeries => {
        let minY = Math.min(...visibleSeries.yValues)
        let maxY = Math.max(...visibleSeries.yValues)

        // Return the new y-axis options for the series, we keep the "opposite" boolean (sometimes left sometimes right)
        return {
          ...chart.w.config.yaxis[visibleSeries.seriesIndex],
          min: minY,
          max: maxY,
          opposite: chart.w.config.yaxis[visibleSeries.seriesIndex].opposite
        }
      })

      // Preserve existing annotations
      let existingAnnotations = chart.w.config.annotations

      // Update the y-axis scale dynamically while preserving annotations and y-axis styles
      try {
        this.$refs[this.chartIndexes].updateOptions({
          yaxis: newYAxisOptions,
          annotations: existingAnnotations // Retain existing annotations
        })
      } catch (e) {
        console.error('Catch updateOptions', e)
      }
    },

    handleNewChartDates() {
      if (this.newChartDates.length) {
        this.$store.commit('reports/SET_REPORT_PERIOD', this.newChartDates)
        this.reloadReport()
      } else {
        this.$store.commit('reports/SET_REPORT_PERIOD', [])
      }
    },
    isNotEmpty(obj) {
      return obj && Object.keys(obj).length > 0
    },
    getStrokeCurveOption() {
      const curve = this.seriesChart.map(item => {
        if (item.key === 'is_trip') return 'stepline'

        return 'straight'
      })

      this.chartOptions.stroke.curve = curve

      console.log(1)
    },

    handleAnnotationChart(intervals, typeInterval) {
      const chartStyles = {
        fillings_intervals: {
          borderColor: '#3A7CCA',
          fillColor: '#3A7CCA',
          icon: 'chart-annotation-filling.svg'
        },
        trips_intervals: {
          borderColor: 'rgba(124, 124, 104, 0.2)',
          fillColor: 'rgba(124, 124, 104, 0.2)',
          label: ''
        },
        default: {
          borderColor: '#EA7644',
          fillColor: '#EA7644',
          icon: 'chart-annotation-theft.svg'
        }
      }

      const { icon, fillColor } = chartStyles[typeInterval] || chartStyles.default
      this.currentAnnotations = []
      intervals.forEach((item, index) => {
        const x = new Date(item[0]).getTime()
        const x2 = new Date(item[1]).getTime()

        // Add x-axis annotation for the interval
        try {
          this.$refs[this.chartIndexes].addXaxisAnnotation({
            x,
            x2,
            fillColor,
            opacity: 0.3,
            strokeDashArray: 2
          })
        } catch (e) {
          console.error('Catch addXaxisAnnotation', e)
        }

        // Add point annotation with the SVG icon
        if (icon) {
          const rndId = Math.floor(Math.random() * 10000)
          const annId = `ann_${rndId}_${index}`

          const annotationId = {
            id: annId,
            x: (x + x2) / 2,
            y: 0,
            marker: {
              strokeWidth: 0,
              radius: 0,
              fillColor: 'rgba(0,0,0,0)'
            },
            image: {
              path: icon,
              width: 44,
              height: 44,
              offsetX: 0,
              offsetY: this.getNewOffsetY()
            }
          }

          try {
            this.$refs[this.chartIndexes].addPointAnnotation(annotationId)
          } catch (e) {
            console.error('Catch addPointAnnotation', e)
          }

          this.fuelAnnotations.push(annId)

          this.currentAnnotations.push(annotationId)
        }
      })
    },
    handleIntervals(intervalKey) {
      const intervals = this.tableData.report.columns.find(el => el.key === intervalKey)
      if (intervals && intervals.data.length > 0) {
        //
        this.handleAnnotationChart(intervals.data, intervalKey)
      }
    },

    convertData(val, isUpdating) {
      const markers = this.tableData.report.columns.filter(el => el.key === '_marker_latlon_chart')
      this.oldMarkers = markers[0].data
      if (!isUpdating && this.isChartMounted) {
        this.seriesBase = []
        this.availableSeries = []
        this.series = []
        const timeArr = []
        const fullArr = []
        const yAxis = []
        const timeCol = this.tableData.report.columns.filter(el => el.key === 'datepoint')

        const barData = this.tableData.report.columns.filter(el => el.key === 'is_trip')

        !isUpdating &&
          this.seriesBase.push({
            data: barData[0].data,
            name: barData[0].name,
            key: barData[0].key,
            type: 'area',
            color: 'transparent',
            show: false
          })

        this.handleIntervals('fillings_intervals')
        this.handleIntervals('thefts_intervals')
        this.handleIntervals('trips_intervals')

        timeCol[0].data.forEach(timedata => {
          const formatedTime = new Date(timedata).getTime()
          timeArr.push(formatedTime)
        })
        val.forEach(el => {
          if (el.key !== 'fillings_intervals' && el.key !== 'thefts_intervals') {
            this.seriesBase.push({
              data: el.data,
              color: el.color,
              key: el.key,
              name: el.name,
              type: 'line'
            })
          }
        })
        this.seriesBase.push({
          show: false,
          data: this.oldMarkers,
          key: 'markers'
        })
        this.seriesBase.forEach((e, j) => {
          fullArr[j] = []
          e.data.map((element, i) => {
            fullArr[j][i] = [timeArr[i], element]
          })
        })
        this.oldFullArr = fullArr

        fullArr.forEach((el, i) => {
          if (i !== fullArr.length - 1) {
            const yAxe = {
              keyName: this.seriesBase[i].key,
              show: true,
              forceNiceScale: true,
              showAlways: false,
              seriesName: this.seriesBase[i].name,
              opposite: true,
              axisTicks: {
                show: true
              },
              axisBorder: {
                show: true,
                color: this.seriesBase[i].color
              },
              labels: {
                style: {
                  colors: this.seriesBase[i].color
                }
              },
              decimalsInFloat: 2,
              logarithmic: false
            }

            //  add min and max to y axis so that the chart can correctly display the small values ( like in zoomed EVENT )
            const visibleYValues = el.map(point => point[1])
            const minY = Math.min(...visibleYValues)
            const maxY = Math.max(...visibleYValues)

            yAxe.min = minY
            yAxe.max = maxY
            yAxis.push(yAxe)
            this.series.push({
              data: el,
              color: this.seriesBase[i].color,
              key: this.seriesBase[i].key,
              name: this.seriesBase[i].name,
              type: this.seriesBase[i]?.type
            })
          } else {
            this.newMarkers = el
          }
        })

        // this.chartOptions = { ...this.chartOptions,yaxis: yAxis }
        this.chartOptions.yaxis = yAxis

        try {
          this.$refs[this.chartIndexes].updateOptions(this.chartOptions)
        } catch (e) {
          console.error('Catch updateOptions', e)
        }

        this.activeCharts = val
        this.isRendered = true
        if (!this.theRawParam || !this.theRawParam.length) {
          this.initTheRawParam()
        }

        if (this.seriesChart.length !== this.series.length) {
          this.seriesChart = [...this.series]
        }
        this.availableSeries = [...this.series]
        this.isNewData = false

        this.getStrokeCurveOption()
      } else {
        this.updateManuallyChart(val, true)
        this.isNewData = true
      }
    },
    ...mapMutations('reports', ['SHOW_POLYLINE']),
    _console() {
      // console.warn.apply(console, arguments)
    },

    // git+https://gitlab.skif.pro/skifapp/web/vue-apexcharts

    updateManuallyChart(val, link) {
      val[val.length - 1] = { data: val[val.length - 1] }
      this.isExtraChart = link
      this.series = []
      const barData = !link ? this.tableData.report.columns.filter(el => el.key === 'is_trip') : val.filter(el => el.key === 'is_trip')

      const barSerie = {
        data: barData[0].data,
        name: barData[0].name,
        key: barData[0].key,
        type: 'area',
        color: 'rgba(124, 124, 104, 0.08)'
      }

      const seriesBase = []
      const timeArr = []
      const fullArr = []
      const yAxis = []
      const formattedNew = []
      seriesBase.push(barSerie)

      if (this.isChartMounted) {
        let timeCol

        if (!link) {
          timeCol = this.tableData.report.columns.filter(el => el.key === 'datepoint')
          timeCol[0].data.forEach(timedata => {
            const formatedTime = new Date(timedata).getTime()
            timeArr.push(formatedTime)
          })
          val.forEach(el => {
            seriesBase.push({
              data: el.data,
              color: el.color,
              key: el.key,
              name: el.name,
              type: 'line'
            })
          })

          seriesBase.forEach((e, j) => {
            fullArr[j] = []
            e.data.map((element, i) => {
              fullArr[j][i] = [timeArr[i], element]
            })
          })
          this.oldFullArr = fullArr
        } else {
          timeCol = [this.currentTimeSeries]
          timeCol[0].data.forEach(timedata => {
            const formatedTime = new Date(timedata).getTime()
            timeArr.push(formatedTime)
          })

          val.forEach((el, index) => {
            const bar = {
              data: el.data,
              color: el.color,
              // this.oldSeries[index] && this.oldSeries[index].color
              //   ? this.oldSeries[index].color
              //   : '',
              key: (this.oldSeries[index] && this.oldSeries[index].key) || el.key,
              name: el.name,
              type: 'line'
            }

            formattedNew.push(bar)
          })
          formattedNew.forEach((e, j) => {
            fullArr[j] = []
            e.data.map((element, i) => {
              fullArr[j][i] = [timeArr[i], element]
            })
          })
        }

        const result = []
        const markers = []
        if (link) {
          const min_datepoint = fullArr[0][0][0]
          const max_datepoint = fullArr[0][fullArr[0].length - 1][0]

          for (let param = 0; param < this.oldFullArr.length; param++) {
            result[param] = []

            let isAdded = false
            for (let point = 0; point < this.oldFullArr[param].length; point++) {
              if (this.oldFullArr[param][point][0] < min_datepoint) {
                result[param][point] = this.oldFullArr[param][point]
                // this.oldMarkers[point] = this.newMarkers[point]
              } else if (this.oldFullArr[param][point][0] > max_datepoint) {
                //  result[param][result[param].length]  = this.oldFullArr[param][point]

                // result[param][fullArr.length + point] = this.oldFullArr[param][
                //   point
                // ]
                result[param].push(this.oldFullArr[param][point])
                // this.oldMarkers.push(this.newMarkers[point])
              } else if (!isAdded) {
                if (fullArr[param]) {
                  fullArr[param].forEach(el => {
                    result[param].push(el)
                    isAdded = true
                  })
                }
              }
            }
          }
        }
        const totalArr = link ? result : fullArr
        totalArr.forEach((el, i) => {
          // this.currentParams.push(seriesBase[i].key)
          // fullArr.forEach((newarr) => {
          //   oldArr.data.forEach((k) => {
          //     newarr.forEach((j) => {})
          //   })
          // })
          let keyName
          let seriesName
          let color

          if (link) {
            if (formattedNew[i]) {
              keyName = formattedNew[i].key
              seriesName = formattedNew[i].name
              color = formattedNew[i].color

              const yAxe = {
                keyName,
                // !link && seriesBase[i] ? seriesBase[i].key : formattedNew[i].key,
                show: true,
                forceNiceScale: true,
                showAlways: false,
                seriesName,
                opposite: i !== 0,
                axisTicks: {
                  show: true
                },
                axisBorder: {
                  show: true,
                  color
                },
                labels: {
                  style: {
                    colors: color
                  }
                },
                decimalsInFloat: 2
              }
              yAxis.push(yAxe)
              this.series.push({
                data: el,
                color,
                key: keyName,
                name: seriesName,
                type: 'line'
              })
            }
          } else {
            if (seriesBase[i]) keyName = seriesBase[i].key
            seriesName = seriesBase[i].name
            color = seriesBase[i].color
            const yAxe = {
              keyName,
              // !link && seriesBase[i] ? seriesBase[i].key : formattedNew[i].key,
              show: true,
              forceNiceScale: false,
              showAlways: false,
              seriesName,
              opposite: i !== 0,
              axisTicks: {
                show: true
              },
              axisBorder: {
                show: true,
                color
              },
              labels: {
                style: {
                  colors: color
                }
              },
              decimalsInFloat: 2
            }
            yAxis.push(yAxe)

            this.series.push({
              data: el,
              color,
              key: keyName,
              name: seriesName,
              type
            })
          }
        })
        this.newMarkers = this.series.pop()

        const idx = this.series.findIndex(item => item.key === 'is_trip')
        this.series[idx].type = 'area'

        try {
          this.$refs[this.chartIndexes].updateOptions({
            ...this.chartOptions,
            series: this.series,
            yaxis: yAxis
          })
        } catch (e) {
          console.error('Catch updateOptions', e)
        }

        // this.chartOptions.xaxis.categories = labels
        this.activeCharts = val
        if (!this.theRawParam || !this.theRawParam.length) {
          this.initTheRawParam()
        }
        this.isRendered = true
      }
      if (this.seriesChart.length !== this.series.length) {
        this.seriesChart = [...this.series]
      }
      this.availableSeries = [...this.series]

      try {
        this.$refs[this.chartIndexes].zoomX(this.min, this.max)
      } catch (e) {
        console.error('catch zoomX', e)
      }
      this.processing = false
      this.ischartloading = false
    },
    onSelectChange(val) {
      const removeArrDiff = this.lastRawParam.filter(i => val.indexOf(i) < 0)
      const removeEl = removeArrDiff[0] || null
      if (removeEl && this.theRawParam.length >= 1) {
        this.removeRawParam(removeEl)
      }

      const addArrDiff = val.filter(i => this.lastRawParam.indexOf(i) < 0)
      const addEl = addArrDiff[0] || null
      if (addEl) {
        const availableSerie = this.availableSeries.find(item => item.key === addEl)
        if (availableSerie) {
          this.series.push(availableSerie)
        }
      }
      this.seriesChart = [...this.series]
      this.lastRawParam = val

      this.getStrokeCurveOption()
      setTimeout(() => {
        this.saveChartImg('apexchart').then(resp => {
          this.$store.commit('reports/CHART_BASE46', resp)
        })
      }, 2000)
    },
    initTheRawParam() {
      const newRawParam = []
      this.series.forEach(serie => {
        newRawParam.push(serie.key)
      })
      this.theRawParam = [...newRawParam]
      this.filterTheRawParam()
      this.lastRawParam = [...this.theRawParam]
    },
    filterTheRawParam() {
      this.theRawParam = this.theRawParam.filter(param => param !== 'is_trip')
    },
    rezoomMap(xaxis) {
      this._console('rezoomMap this.params: ', this.params)
      // this.$store.dispatch('reports/GET_TRACK', {
      //   ...this.params,
      //   zoom: 14,
      //   from: moment(xaxis.min).format('YYYY-MM-DD HH:mm:ss'),
      //   to: moment(xaxis.max).format('YYYY-MM-DD HH:mm:ss'),
      // })
    },
    handlClose() {
      this.$store.dispatch('reports/removechartLocations', true)
      this.$emit('close')
    },
    handlZoom({ xaxis }) {
      this._console('GET_REPORT 185')
      this.$store.dispatch('reports/GET_REPORT', {
        ...this.params,
        from: moment(xaxis.min).format('YYYY-MM-DD HH:mm:ss'),
        to: moment(xaxis.max).format('YYYY-MM-DD HH:mm:ss'),
        max: 3999
      })
    },

    dragMouseDown(event) {
      event.preventDefault()
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },
    elementDrag(event) {
      event.preventDefault()
      this.positions.movementX = this.positions.clientX - event.clientX
      this.positions.movementY = this.positions.clientY - event.clientY
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      // set the element's new position:
      this.$refs.draggableContainer.style.top = `${this.$refs.draggableContainer.offsetTop - this.positions.movementY}px`
      this.$refs.draggableContainer.style.left = `${this.$refs.draggableContainer.offsetLeft - this.positions.movementX}px`
    },
    closeDragElement() {
      this._console('closeDragElement')
      document.onmouseup = null
      document.onmousemove = null
    },

    handleLegendClick(chart, index) {
      this._console('handleLegendClick')
      const currentLegend = this.series[index].key
      this.series = this.series.filter(item => item.key !== currentLegend)
      const newyaxis = this.chartOptions.yaxis.filter(item => {
        return item.keyName !== currentLegend
      })
      this.seriesChart = [...this.series]
      this.chartOptions = { ...this.chartOptions, yaxis: newyaxis }

      const newRawParam = this.theRawParam.filter(item => item !== currentLegend)
      this.theRawParam = newRawParam

      // if (this.theRawParam.includes(currentLegend)) {

      // }
    },
    showChart(chart, index) {
      console.log('showChart')
    },
    addRawParam(isAppearing) {
      if (this.isFirstLoad) {
        return
      }
      let intervals
      if (this.currentConfig) {
        intervals = this.currentConfig.selected_intervals
      }
      const newPaginatedReport = {
        ...this.paginatedReport,
        selected_columns: this.theRawParam.map(param => {
          return {
            name: param,
            key: param
          }
        })
      }
      if (intervals) {
        newPaginatedReport.selected_intervals = intervals
      }
      this.newPaginatedReport = newPaginatedReport
      if (
        // !this.theRawParam.every((item) =>
        //   this.rowTableDataKeys.includes(item)
        // ) &&
        !isAppearing
      ) {
        // this.$emit('update:paginatedReport', newPaginatedReport)
        const newChart = {
          template_tables: this.params.template_tables,
          units: this.params.units ? this.params.units : this.params.groups,
          from: moment(this.fromForSpecChart).format('YYYY-MM-DD HH:mm:ss'),
          to: moment(this.toForSpecChart).format('YYYY-MM-DD HH:mm:ss'),
          selected_columns: this.theRawParam.map(param => {
            return {
              name: param,
              key: param
            }
          })
        }
        if (intervals) {
          newChart.selected_intervals = intervals
        }
        if (this.isSpecificChart) {
          this.$nextTick(async () => {
            this.selectDisabled = true
            console.log('this.isSpecificChart', this.isSpecificChart)
            try {
              this.$refs[this.chartIndexes].updateSeries([]).then(() => console.log('GET_REPORT 555', newPaginatedReport))
            } catch (e) {
              console.error('catch updateSeries', e)
            }

            await this.$store.dispatch('reports/GET_REPORT', newChart)

            this.selectDisabled = false
          })

          // this.$store.dispatch('reports/GET_REPORT', newChart)
        } else {
          this.seriesChart = this.series = this.seriesBase = this.oldSeries = this.availableSeries = []

          this.$nextTick(async () => {
            this.selectDisabled = true

            try {
              this.$refs[this.chartIndexes].updateSeries([]).then(() => console.log('GET_REPORT 333', newPaginatedReport))
            } catch (e) {
              console.error('catch updateSeries', e)
            }

            await this.$store.dispatch('reports/GET_REPORT', newPaginatedReport)

            this.selectDisabled = false
          })
        }
      }
    },
    removeRawParam(el) {
      if (this.series.length >= 2) {
        this.series.forEach(raw => {
          if (raw.key === el && raw.key !== 'is_trip') {
            const index = this.series.indexOf(raw)

            this.seriesBase.splice(index, 1)
            this.chartOptions.yaxis.splice(index, 1)
            this.series.splice(index, 1)
          }
        })
      } else if (this.series.length === 1) {
        console.log('3 removeRawParam')
        // this.theRawParam.push(this.series[0].key)
      }
      this.seriesChart = [...this.series]
      this.addRawParam()

      try {
        this.$refs[this.chartIndexes].updateSeries(this.serieChart)
        this.$refs[this.chartIndexes].updateOptions(this.chartOptions)
      } catch (e) {
        console.error('Catch updateOptions', e)
      }
    },
    async saveChartImg(chartId) {
      const chartInstance = window.Apex._chartInstances.find(chart => chart.id === chartId)
      return await chartInstance.chart.dataURI()
    },
    refreshReport() {
      console.log('refreshReport')
      this.ischartloading = true
      if (this.currentConfig && this.currentConfig.selected_intervals && this.currentConfig.selected_intervals.length > 0) {
        console.log(555, this.currentConfig)
        this.$store.dispatch('reports/GET_REPORT', this.currentConfig)
      } else {
        this.$store.dispatch('reports/GET_REPORT', {
          ...this.params
        })
      }
      setTimeout(() => {
        this.ischartloading = false
      }, 500)
    },

    reloadReport() {
      this.$emit('reload-report')
      this.$emit('close')
    }
  },
  beforeDestroy() {
    const openedReports = JSON.parse(localStorage.getItem('openedReports'))
    if (openedReports.length === 1) {
      console.log(this.tableData)
    }
    //   this.$store.commit('reports/SET_CHART_ZOOM', [])
  },

  mounted() {
    const openedReports = JSON.parse(localStorage.getItem('openedReports'))
    if (openedReports) {
      openedReports.forEach(el => {
        if (el.key === 'chart') {
          if (el.closed_to_change) {
            this.closeToChange = true
          }
        }
      })
    }

    // this.tableData.report.columns.forEach((el) => {
    //   if (el.key === 'thefts_intervals') {
    //     this.isDrainsIntervals = true
    //   } else if (el.key === 'fillings_intervals') {
    //     this.isFillingsIntervals = true
    //   }
    // })
    this.dateFormat = this.me.active_company.dateformat.key.toUpperCase()
    this.timeFormat = this.me.active_company.timeformat.key
    this.dateTimeFormat = `${this.dateFormat} ${this.timeFormat}`

    const dateFromFull = this.paginatedReport.from.split(' ')
    const dateFrom = moment(dateFromFull[0]).format(this.dateFormat)
    const timeFrom = moment(dateFromFull[1], this.timeFormat).format(this.timeFormat)
    this.dateTitleFrom = `${dateFrom} ${timeFrom}`

    const dateToFull = this.paginatedReport.to.split(' ')
    const dateTo = moment(dateToFull[0]).format(this.dateFormat)
    const timeTo = moment(dateToFull[1], this.timeFormat).format(this.timeFormat)
    this.dateTitleTo = `${dateTo} ${timeTo}`

    this.seriesChart = []
    this.SHOW_POLYLINE(true)
    setTimeout(() => {
      this.firstTimeFirst = this.tableData.report.columns[0].data[0]
      this.firstTimeLast = this.tableData.report.columns[0].data.pop()
      this.maxRows = this.oldMaxRows = this.tableData.report.max_rows
      this.oldSeries = this.series
      const downloadBtn = document.getElementsByClassName('exceldownload')
      const self = this
      downloadBtn[0].addEventListener('click', function () {
        const from = moment(self.paginatedReport.from)
        const to = moment(self.paginatedReport.to)
        const days = to.diff(from, 'days')

        // if (days < 14) {
        const formData = {
          units: self.paginatedReport.units,
          template_tables: [
            {
              id: 'c9e29985-ed8e-4b5a-9356-c1d393207eaa'
            }
          ],
          from: self.paginatedReport.from,
          to: self.paginatedReport.to,
          selected_columns: self.theRawParam.map(param => {
            return {
              name: param,
              key: param
            }
          }),
          max: 1000,
          offset: 0,
          sortField: null,
          sortDesc: false,
          conditions: []
        }
        window.location.href = `${window.location.protocol}//${location.host}/api_v1/report_excel?body=${JSON.stringify(formData)}`
        // } else {
        //   self.$showError('Невозможно загрузить график за более 2 недели')
        // }
      })
    }, 4000)
    this.isChartMounted = true
    setTimeout(() => {
      this.saveChartImg('apexchart').then(resp => {
        this.$store.commit('reports/CHART_BASE46', resp)
      })
    }, 2000)

    this.processing = false
  }
}
</script>

<style lang="stylus">
.chartIntervals{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  left: 1317px;

}
.intervals_block {
    display: flex;
    align-items: center;
    .color {
      width: 16px;
      height: 12px;
      border-radius: 2px;
    }
    p{
      margin-left: 3px;
      font-seize: 14px;
      color: #292929;
      vertical-align: middle;
      font-weight: 400;
      line-height: 19px;
    }
    .grey {
      background-color: #DEDEDE;
    }
    .orange{
      background-color: #F3C9B5
    }
    .blue{
      background-color: #B4D3F4
    }
  }
.exceldownload, .depend {
  margin-left: 5px;
}
.apexcharts-title-text,
.apexcharts-subtitle-text {
  display: none;
}
.depend {
  &.disabled {
    cursor: default;
    color: #607686;
  }
  &.active {
    color: #0084F5;
  }
  &.nonactive:hover {
     color: #2D2D2D;
  }
}
</style>

<style lang="stylus" scoped>

.el-select {
  &.--disabled {
    opacity: 0.5;
  }

  &.--one-value >>> .el-tag__close {
    display: none;
  }
}
</style>

<style lang="stylus" scoped>
.sensor {
  display: flex;
  margin: 0 10px;
  height: 22px;
  align-items: center;
  cursor: pointer;
  padding: 0 5px;
  border-radius: 5px;

  &.active {
    background-color: #F0F0F0;

    span {
      color: #0B2345;
      font-weight: 500;
    }
  }

  .sensor-color {
    width: 10px;
    height: 10px;
    margin-top: 2px;
    border-radius: 5px;
    margin-bottom: 2px;
  }

  #draggable-container {
    position: absolute;
    z-index: 9;
  }

  #draggable-header {
    z-index: 10;
  }

  span {
    margin-left: 8px;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #5477A9;
  }
}

.bottom-side {
  background: #fff;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.middle {
  .el-radio-group {
    padding-bottom: 26px;
    display: flex;
    justify-content: space-between;
  }
}

.minus {
  padding-bottom: 5px;
}

.chartControl {
  border: 2px solid #5477A9;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  margin: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5477A9;
  font-size: 20px;
  font-weight: bold;
}

.chartBtn {
  border: 2px solid #5477A9;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0 4px;
}

.reloadReport {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 10px 12px;
  background-color: #DBEBFF;
  border-radius: 12px;
  .text {
    margin-left: 8px;
    color: #20579A;
    .no-data {
      font-size: 12px;
    }
    .reload {
      font-size: 14px;
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
<style>
.apexcharts-toolbar {
  max-width: 225px !important;
  position: absolute;
  right: auto;
  left: 45%;
  top: -36px !important;
  bottom: auto;
}
.depend {
  order: 6;
}
.apexcharts-pan-icon {
  order: 5;
}
.apexcharts-zoom-icon {
  order: 4;
}
.apexcharts-menu-icon {
  order: 3;
  margin-right: 22px;
}
.exceldownload {
  order: 2;
  margin-left: 22px;
}
.apexcharts-reset-icon {
  margin: 0 !important;
  order: 1;
}

.apexcharts-tooltip {
  position: absolute;
  top: 0;
  background-color: #ffffffde !important;
  color: #242f3f;
  border: 1px solid #242f3f !important;
}
.apexcharts-tooltip-title {
  height: 15px !important;
  background-color: rgb(255, 255, 255) !important;
  margin: 0 !important;
  padding: 0 !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  border: none !important;
}
.apexcharts-tooltip-series-group {
  padding: 0 !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  height: 15px !important;
}
.apexcharts-tooltip-text {
  font-size: 11px !important;
}

.not-data {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 0 0 12px 12px;
  z-index: 21;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
}
.header-cont {
  width: 35%;
  right: 0;
  position: absolute;
  margin-top: 10px;
  margin-right: 20px;
}

@media (min-width: 481px) and (max-width: 768px) {
  .header-cont {
    width: 130px;
  }
}

@media (min-width: 769px) and (max-width: 900px) {
  .header-cont {
    width: 20%;
  }
}

@media (min-width: 901px) and (max-width: 1076px) {
  .header-cont {
    width: 24%;
  }
}
@media (min-width: 1003px) and (max-width: 1097px) {
  .header-cont {
    width: 28%;
  }
}
@media (min-width: 1098px) and (max-width: 1440px) {
  .header-cont {
    width: 30%;
  }
}
.xaxis-annotation-label {
}
</style>
